<template>
  <transition name="fadein" @after-leave="afterLeave">
    <div v-show="show && !disabled" ref="popper" class="w-tooltips">
      <div data-popper-arrow style="width: 8px; height: 8px"></div>
      {{ msg }}
    </div>
  </transition>
</template>

<script>
import Popper from '../../popper';

export default {
  mixins: [Popper],
  data() {
    return {
      show: false,
      msg: '',
      disabled: false,

      referenceElm: null,
      currentPopperModifiers: {
        arrow: { padding: 6 },
      },
    };
  },
  computed: {
    tooltipsShow() {
      return this.show && !this.disabled;
    },
  },
  watch: {
    tooltipsShow(val) {
      if (val) {
        this.$nextTick(() => {
          this.updatePopper();
        });
      }
    },
  },
  mounted() {
    this.popperElm = this.$refs.popper;
  },
  methods: {
    afterLeave() {
      this.doDestroy();
    },
    onReferenceHide() {
      this.show = false;
    },
  },
};
</script>

export default function retryAdapterEnhancer(adapter) {
  return (config) => {
    const { retryTimes = 0 } = config;
    let timeUp = false;
    let count = 1;

    // 不需重试直接返回
    if (retryTimes === 0) {
      return adapter(config);
    }
    // 需要重试，则重试当前请求
    const request = async () => {
      try {
        return await adapter(config);
      } catch (e) {
        timeUp = retryTimes === count;
        if (timeUp) {
          throw e;
        }
        count += 1;
        return request();
      }
    };

    return request();
  };
}

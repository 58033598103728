<template>
  <div
    class="w-spinner"
    :class="[`w-spinner--${size}`, `w-spinner--${theme}`]">
    <div class="w-spinner1 w-spinner-child"></div>
    <div class="w-spinner2 w-spinner-child"></div>
    <div class="w-spinner3 w-spinner-child"></div>
    <div class="w-spinner4 w-spinner-child"></div>
    <div class="w-spinner5 w-spinner-child"></div>
    <div class="w-spinner6 w-spinner-child"></div>
    <div class="w-spinner7 w-spinner-child"></div>
    <div class="w-spinner8 w-spinner-child"></div>
    <div class="w-spinner9 w-spinner-child"></div>
    <div class="w-spinner10 w-spinner-child"></div>
    <div class="w-spinner11 w-spinner-child"></div>
    <div class="w-spinner12 w-spinner-child"></div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'm',
      validator(value) {
        return ['s', 'm', 'l'].indexOf(value) !== -1;
      },
    },
    theme: {
      type: String,
      default: 'normal',
      validator(value) {
        return ['normal', 'primary', 'danger', 'secondary',
          'normal-2', 'normal-3', 'normal-4'].indexOf(value) !== -1;
      },
    },
  },
};
</script>

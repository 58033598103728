// eslint-disable-next-line
export const TestHosts = [
  {
    key: 0,
    label: 'wagon-test',
    host: 'https://wagon-test.webapp.easebar.com',
  }, {
    key: 1,
    label: 'wagon-c2c',
    host: 'https://wagon-c2c.webapp.easebar.com',
  }, {
    key: 2,
    label: 'wagon-release',
    host: 'https://wagon-release.webapp.easebar.com',
  }, {
    key: 3,
    label: 'wagon-wq',
    host: 'https://wagon-wq.dev.webapp.163.com:8007',
  }, {
    key: 4,
    label: 'wagon-lh',
    host: 'https://wagon-lh.webapp.easebar.com',
  }, {
    key: 5,
    label: 'wagon-test2',
    host: 'https://wagon-test2.webapp.easebar.com',
  }, {
    key: 6,
    label: 'wagon-yhj',
    host: 'https://wagon-yhj.webapp.easebar.com',
  }, {
    key: 7,
    label: 'wagon-zcz',
    host: 'https://wagon-zcz.webapp.easebar.com',
  }, {
    key: 8,
    label: 'wagon-wq-test',
    host: 'http://wagon-wq-test.dev.webapp.163.com:8007',
  },
];

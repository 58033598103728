import { generateUrl as generateUrl2 } from './utils';

export default {
  // 获取在售列表
  getMarketSelling() {
    return generateUrl2('/api/market/sell_order/on_sale', false);
  },
  // 获取sell历史
  getMarketSellOrderHistory() {
    return generateUrl2('/api/market/sell_order/history', false);
  },
  // 下架饰品
  cancelSellOrder() {
    return generateUrl2('/api/market/sell_order/cancel', false);
  },
  // 下架全部饰品
  cancelAllSellOrder() {
    return generateUrl2('/api/market/sell_order/cancel_all', false);
  },
  // 发货
  // postOrderDeliver() {
  //   return generateUrl('/api/market/bill_order/deliver', false);
  // },
  // 单个取消发货
  // cancelOrderDeliver() {
  //   return generateUrl('/api/market/bill_order/deliver/cancel', false);
  // },
  // 单个取消发货弹窗
  // cancelOrderDeliverPreview() {
  //   return generateUrl('/api/market/bill_order/deliver/cancel/preview', false);
  // },
  // 改价
  changeSellOrderPrice() {
    return generateUrl2('/api/market/sell_order/change', false);
  },
  // 查询收藏
  checkBookmark() {
    return generateUrl2('/api/account/check_bookmark', false);
  },
};

<template>
  <div class="w-menu-group">
    <h2 v-if="label">
      {{ label }}
      <div class="w-menu-group__adv">
        <slot name="advanced"></slot>
      </div>
    </h2>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
  },
};
</script>

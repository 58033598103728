var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-textarea",class:[
    {
      'w-textarea--count': _vm.showLimit,
      'is-disabled': _vm.textareaDisabled,
      'is-reating': _vm.textareaReating,
      'is-error': _vm.textareaError,
      'has-prefix': _vm.$slots.prefix,
    },
  ]},[(_vm.$slots.prefix)?_c('div',{staticClass:"w-textarea__prefix",on:{"mousedown":function($event){$event.preventDefault();}}},[_vm._t("prefix")],2):_vm._e(),(!_vm.textareaReating)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.val),expression:"val"}],ref:"input",staticClass:"w-textarea__inner",style:({
      height:_vm.autoHeight?_vm.height:'',
      maxHeight:_vm.autoHeight?(_vm.maxHeight+'px'):'',
    }),attrs:{"placeholder":_vm.placeholder,"disabled":_vm.textareaDisabled,"rows":_vm.rows},domProps:{"value":(_vm.val)},on:{"paste":_vm.handlePaste,"input":[function($event){if($event.target.composing)return;_vm.val=$event.target.value},_vm.handleInput],"focus":_vm.handleFocus,"blur":_vm.handleBlur,"compositionstart":function($event){_vm.isCompositing = true},"compositionend":function($event){_vm.isCompositing = false}}}):_c('div',{staticClass:"w-textarea__inner"},[_vm._v(_vm._s(_vm.val))]),(_vm.showLimit)?_c('div',{staticClass:"w-textarea__count",class:[_vm.countError ? 'w-textarea__count--overflow' : '']},[_c('span',[_vm._v(_vm._s(_vm.curLength))]),_vm._v("/"+_vm._s(_vm.maxLength)+" ")]):(_vm.showActions)?_c('div',{staticClass:"w-textarea__actions",on:{"mousedown":function($event){$event.preventDefault();}}},[_vm._t("default")],2):_vm._e(),(_vm.resize !== 'none')?_c('w-icon',{staticClass:"w-textarea__resize",class:[_vm.resizeCursorDown ? 'is-grabbing' : ''],attrs:{"name":"icon_boxresize","size":8},nativeOn:{"mousedown":function($event){return _vm.handleResizeMousedown.apply(null, arguments)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <ul class="w-switcher">
    <li v-for="option in options" :key="option.key"
      class="w-switcher-item"
      :class="{'w-switcher-item--on': active === option.key}"
      @click="active = option.key">
      {{ option.label }}
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    options: Array,
  },
  computed: {
    active: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"reference",class:[
    'w-select',
    `w-select--${_vm.size}`,
    {
      'is-disabled': _vm.selectDisabled,
      'is-reating': _vm.selectReating,
      'w-select--selected': _vm.selected,
      'w-select--active': _vm.active,
    },
  ],attrs:{"tabindex":"0"}},[_c('div',{staticClass:"w-select__wrap",class:{'is-placeholder': !_vm.selected},on:{"click":_vm.handleToggleActive}},[_c('div',{staticClass:"w-select__inner"},[(!_vm.selected)?[_vm._v(_vm._s(_vm.placeholder))]:[(_vm.$scopedSlots.default)?[_vm._t("default",null,{"selected":_vm.selected})]:[_vm._v(_vm._s(_vm.selected.label))]]],2),_c('i',{staticClass:"w-select__icon"})]),_c('SelectDropdown',{ref:"dropdown",staticClass:"w-select-dropdown",class:[
      _vm.type ? `w-select-dropdown--${_vm.type}` : '',
    ],attrs:{"show":_vm.active,"title":_vm.title},on:{"close":_vm.handleCloseActive}},[_c('w-scrollbar',{attrs:{"wrap-class":"w-select-dropdown__wrap","auto-height":""}},[_vm._l((_vm.options),function(option,index){return [(option.key !== undefined)?_c('div',{key:option.key,staticClass:"w-select-dropdown__item",class:{'is-selected': _vm.selected && option.key === _vm.selected.key},on:{"click":function($event){return _vm.handleSelect(option)}}},[(_vm.$scopedSlots.option)?_vm._t("option",null,{"option":option}):[_vm._v(_vm._s(option.label))],(_vm.selected && option.key === _vm.selected.key)?_c('w-icon',{attrs:{"name":"icon_done","size":20}}):_vm._e()],2):(option.label)?_c('div',{key:option.label,staticClass:"w-select-dropdown__label"},[(_vm.$scopedSlots.label)?_vm._t("label",null,{"option":option}):[_vm._v(_vm._s(option.label))]],2):_c('hr',{key:`hr-${index}`,staticClass:"w-select-dropdown__hr"})]})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
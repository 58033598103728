import icon from './icon';
import button from './button';
import input from './input';
import select from './select';
import checkbox from './checkbox';
import switcher from './switcher';
import menu from './menu';
import form from './form';
import scrollbar from './scrollbar';
import modal from './modal';
import dialog from './dialog';
import popover from './popover';
import tooltips from './tooltips';
import spinner from './spinner';
import count from './count';
import './theme/index.less';

const packages = {};
packages.install = (VueInstance) => {
  VueInstance.use(icon);
  VueInstance.use(button);
  VueInstance.use(input);
  VueInstance.use(select);
  VueInstance.use(checkbox);
  VueInstance.use(switcher);
  VueInstance.use(menu);
  VueInstance.use(form);
  VueInstance.use(scrollbar);
  VueInstance.use(modal);
  VueInstance.use(dialog);
  VueInstance.use(popover);
  VueInstance.use(tooltips);
  VueInstance.use(spinner);
  VueInstance.use(count);
};

export default packages;

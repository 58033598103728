<template>
  <div
    ref="reference"
    :class="[
      'w-select',
      `w-select--${size}`,
      {
        'is-disabled': selectDisabled,
        'is-reating': selectReating,
        'is-dropdown-hover': hover || showSubDropdown,
        'w-select--selected': selected,
        'w-select--active': active,
        [`w-select--${theme}`]: theme !== 'light',
      },
    ]"
    tabindex="0">
    <div class="w-select__wrap" :class="{'is-placeholder': !selected}" @click="handleToggleActive">
      <div class="w-select__inner">
        <template v-if="!selected">{{ placeholder }}</template>
        <template v-else>
          <template v-if="$scopedSlots.default">
            <slot :selected="selected"></slot>
          </template>
          <template v-else>
            {{ selected.label }}
          </template>
        </template>
      </div>
      <i class="w-select__icon"></i>
    </div>
    <SelectDropdown
      v-show="active"
      ref="popper"
      class="w-select-dropdown"
      :class="[
        type ? `w-select-dropdown--${type}` : '',
        { 'theme-dark': theme === 'dark' }]"
      :offset="[0, 8]"
      @mouseover.native="hover = true"
      @mouseleave.native="hover = false">
      <w-scrollbar :style="style"
        :prevent-bar="showSubDropdown"
        wrap-class="w-select-dropdown__wrap"
        auto-height>
        <template v-for="(option, index) in options">
          <div
            v-if="option.key !== undefined"
            :key="option.key"
            class="w-select-dropdown__item"
            :class="{'is-hover': showSubDropdown && hoverOption.key === option.key}"
            @click="handleSelect(option)"
            @mouseenter.self="handleMouseenterOption(option, $event)"
            @mouseleave.self="handleMouseoutOption()">
            <slot
              v-if="$scopedSlots.option"
              name="option"
              :option="option"></slot>
            <template v-else>
              {{ option.label }}
              <i v-if="option.sub" class="w-select__icon"></i>
            </template>
          </div>
          <div
            v-else-if="option.label"
            :key="option.label"
            class="w-select-dropdown__label">
            <slot v-if="$scopedSlots.label" name="label" :option="option"></slot>
            <template v-else>{{ option.label }}</template>
          </div>
          <hr v-else :key="`hr-${index}`" class="w-select-dropdown__hr" />
        </template>
      </w-scrollbar>
    </SelectDropdown>
    <SelectSubDropdown
      v-if="showSubDropdown"
      ref="subPopper"
      :options="hoverOption.sub"
      :reference="hoverEl"
      @mouseenter.native="hoverSubDropdown = true"
      @mouseleave.native="hoverSubDropdown = false"
      @select="handleSelect" />
  </div>
</template>

<script>
// import Scrollbar from '@/components/Scrollbar';

import SelectDropdown from './select-dropdown.vue';
import SelectSubDropdown from './select-sub-dropdown.vue';

export default {
  components: { SelectDropdown, SelectSubDropdown },
  inject: {
    theme: {
      default: 'light',
    },
    form: {
      default: {},
    },
  },
  props: {
    value: [String, Number],
    size: {
      type: String,
      default: 'm',
    },
    disabled: Boolean,
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    placeholder: String,
    dropdownMaxHeight: Number,
    expandable: { // 弹窗宽度是否可大于输入框
      type: Boolean,
      default: true,
    },
    type: String,
  },
  data() {
    return {
      active: false,
      popup: null,
      hover: false,

      hoverOption: null,
      hoverEl: null,
      hoverSub: false,
      hoverSubDropdown: false,
    };
  },
  computed: {
    showSubDropdown() {
      return this.hoverSubDropdown || this.hoverSub;
    },
    selected: {
      get() {
        const { value } = this;
        if (value === undefined || value === '' || value === null) return null;
        function match(options) {
          let matched = null;
          for (let i = 0; i < options.length; i += 1) {
            if (options[i].key === value) matched = options[i];
            else if (options[i].sub) matched = match(options[i].sub);
            if (matched) return matched;
          }
          return matched;
        }
        return match(this.options);
      },
      set(val) {
        this.$emit('input', val.key, val);
      },
    },
    selectDisabled() {
      return this.disabled || this.form.disabled;
    },
    selectReating() {
      return this.reating || this.form.reating;
    },
    style() {
      if (this.dropdownMaxHeight) {
        return { maxHeight: `${this.dropdownMaxHeight}px` };
      }
      return '';
    },
  },
  watch: {
    selectDisabled(val) {
      if (val) this.active = false;
    },
    active(val) {
      if (val) {
        this.bindEvents();
        this.$emit('focus');
      } else {
        this.unbindEvents();
        this.$emit('blur');
      }
    },
    showSubDropdown(val) {
      if (val) {
        this.$nextTick(() => {
          const { subPopper, reference } = this.$refs;
          const key = this.expandable ? 'minWidth' : 'width';
          subPopper[key] = reference.getBoundingClientRect().width;
        });
      } else {
        const { subPopper } = this.$refs;
        subPopper.doDestroy();
      }
    },
  },
  mounted() {
    // const { wrap, dropdown } = this.$refs;
    // window.popper = createPopper(wrap, dropdown, {
    //   placement: 'bottom-start',
    //   strategy: 'fixed',
    //   modifiers: [
    //     {
    //       name: 'flip',
    //       options: {
    //         fallbackPlacements: ['top-start'],
    //         boundary: document.querySelector('.settings-voice'),
    //         // boundary: document.querySelector('#app'),
    //       },
    //     },
    //     {
    //       name: 'preventOverflow',
    //       options: {
    //         altAxis: true,
    //         boundary: document.querySelector('#app'),
    //       },
    //     },
    //     {
    //       name: 'offset',
    //       options: {
    //         offset: [0, 10],
    //       },
    //     },
    //   ],
    // });
  },
  beforeDestroy() {
    this.unbindEvents();
  },
  methods: {
    handleCloseActive() {
      this.active = false;
    },
    handleToggleActive() {
      if (this.selectDisabled) return;
      if (this.active) {
        this.handleCloseActive();
      } else {
        this.active = true;
        this.$nextTick(() => {
          const { popper, reference } = this.$refs;
          const key = this.expandable ? 'minWidth' : 'width';
          popper[key] = reference.getBoundingClientRect().width;
          popper.updatePopper();
        });
      }
    },
    handleSelect(option) {
      if (option.disabled) return;
      this.selected = option;
      this.hoverSubDropdown = false;
      this.handleCloseActive();
    },
    handleMouseenterOption(option, event) {
      this.hoverOption = option;
      this.hoverEl = event.target || event.srcElement;
      this.hoverSub = option.sub?.length > 0 ?? false;
    },
    handleMouseoutOption() {
      this.hoverSub = false;
    },
    bindEvents() {
      window.addEventListener('mousedown', this.mousedownEvent, true);
      window.addEventListener('mouseup', this.mouseupEvent, { passive: false });
    },
    unbindEvents() {
      window.removeEventListener('mousedown', this.mousedownEvent, true);
      window.removeEventListener('mouseup', this.mouseupEvent, { passive: false });
    },
    mousedownEvent(e) {
      const { popperElm } = this;
      const { target } = e;
      this.closeOnMouseup = !popperElm.includeNode(target);
    },
    mouseupEvent(e) {
      if (this.closeOnMouseup) {
        e.preventDefault();
        this.handleCloseActive();
      }
    },
  },
};
</script>

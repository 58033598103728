<template>
  <div class="w-popover">
    <transition name="fadein" @after-leave="afterLeave">
      <div
        v-if="show"
        ref="inner"
        class="w-popover__inner"
        @mouseover="handleMouseover"
        @mouseleave="handleMouseleave">
        <slot></slot>
      </div>
    </transition>
    <div
      ref="ref"
      class="w-popover__ref"
      :class="{'is-active': show}"
      @mouseover="handleMouseover"
      @mouseleave="handleMouseleave"
      @click="handleClick">
      <slot name="reference"></slot>
    </div>
  </div>
</template>

<script>
import Popper from '../../popper';

export default {
  name: 'w-popover',
  mixins: [Popper],
  props: {
    offset: Array,
    placement: {
      type: String,
      default: 'bottom',
    },
    trigger: {
      type: String,
      default: 'hover', // hover,click
    },
    disabled: Boolean,
  },
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    this.referenceElm = this.$refs.ref;
    this.currentPlacement = this.placement;
    this.currentPopperModifiers = {
      preventOverflow: {
        altAxis: true,
        boundary: document.querySelector('#app'),
      },
    };
    this.updatePopper();
    this.doDestroy();
  },
  methods: {
    checkClose(e) {
      const el = this.$refs.inner;
      const { target } = e;
      let currentNode = target;
      let flag = false;
      while (currentNode !== document.body) {
        if (currentNode === el) {
          flag = true;
          break;
        }
        currentNode = currentNode.parentNode;
      }
      if (!flag) {
        // 点击外部时关闭自身
        this.hidePopover();
      }
    },
    showPopover() {
      if (this.disabled) return;
      this.show = true;
      this.$emit('show');
      // 自动为button组件添加focus样式
      let refVNode = this.$slots.reference[0];

      while (refVNode.children && !refVNode.componentInstance) {
        refVNode = refVNode.children && refVNode.children[0];
      }
      let instance = refVNode.componentInstance;
      while (instance) {
        if (['w-button', 'w-input'].indexOf(instance.$options.name) !== -1) {
          instance.injectFocus = true;
          break;
        } else {
          instance = instance.$children && instance.$children[0];
        }
      }

      this.$nextTick(() => {
        this.popperElm = this.$refs.inner;
        this.updatePopper();
      });
    },
    hidePopover() {
      this.show = false;
      this.$emit('close');
      if (this.trigger === 'click') {
        document.body.removeEventListener('click', this.checkClose, true);
      }
      // 移除button组件添加focus样式
      let refVNode = this.$slots.reference[0];

      while (refVNode.children && !refVNode.componentInstance) {
        refVNode = refVNode.children && refVNode.children[0];
      }
      let instance = refVNode.componentInstance;
      while (instance) {
        if (['w-button', 'w-input'].indexOf(instance.$options.name) !== -1) {
          instance.injectFocus = false;
          break;
        } else {
          instance = instance.$children && instance.$children[0];
        }
      }
    },
    handleMouseover() {
      if (this.trigger !== 'hover') return;
      if (this.timer) {
        window.clearTimeout(this.timer);
        this.timer = null;
      }
      this.showPopover();
    },
    handleMouseleave() {
      if (this.trigger !== 'hover') return;
      this.timer = setTimeout(() => {
        this.hidePopover();
      }, 16);
    },
    handleClick() {
      if (this.trigger !== 'click') return;
      if (this.show) {
        this.hidePopover();
        return;
      }
      this.showPopover();
      this.$nextTick(() => {
        document.body.addEventListener('click', this.checkClose, true);
      });
    },
    afterLeave() {
      this.doDestroy();
    },
  },
};
</script>

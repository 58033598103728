import { mergeDeep } from '../utils';
import PopupManager from '../popup-manager';

const stop = (e) => e.stopPropagation();

let createPopper = null;
let createPopperPromise = null;

export default {
  props: {
    reference: {},
    popper: {},
    placement: {},
    offset: { default: 0 },
    appendToBody: {
      type: Boolean,
      default: true,
    },
    popperModifiers: {
      type: Object,
      default() {
        return {
          computeStyles: {
            gpuAcceleration: false,
          },
        };
      },
    },
    zIndex: Number,
  },
  methods: {
    async createPopper() {
      const options = {};

      // eslint-disable-next-line
      const popper = this.popperElm = this.popperElm || this.popper || this.$refs.popper;
      // eslint-disable-next-line
      const reference = this.referenceElm = this.referenceElm || this.reference || this.$refs.reference;
      if (!popper || !reference) return;
      if (!createPopper) await createPopperPromise;
      if (this.appendToBody) {
        document.body.appendChild(this.popperElm);
      }
      if (this.popperJS && this.popperJS.destroy) {
        this.popperJS.destroy();
      }

      options.placement = this.currentPlacement || this.placement;

      const modifiers = mergeDeep(this.currentPopperModifiers || {}, this.popperModifiers || {});

      options.modifiers = Object.keys(modifiers).map((key) => ({
        name: key,
        options: modifiers[key],
      }));
      if (this.offset) {
        options.modifiers.push({
          name: 'offset',
          options: { offset: this.offset },
        });
      }
      if (typeof this.onReferenceHide === 'function') {
        options.modifiers.push({
          name: 'onReferenceHide',
          enabled: true,
          requires: ['hide'],
          phase: 'write',
          fn: ({ state }) => {
            if (state.modifiersData.hide.isReferenceHidden) {
              this.onReferenceHide(state);
            }
          },
        });
      }
      options.onFirstUpdate = () => {
        this.$emit('created', this);
        this.$nextTick(this.updatePopper);
      };
      this.popperJS = createPopper(reference, popper, options);
      this.popperElm.addEventListener('click', stop);
      this.popperElm.style.zIndex = this.zIndex ?? PopupManager.nextZIndex();
    },

    updatePopper() {
      const { popperJS } = this;
      if (popperJS) {
        popperJS.update();
        this.popperElm.style.zIndex = this.zIndex ?? PopupManager.nextZIndex();
      } else {
        this.createPopper();
      }
    },

    doDestroy() {
      if (!this.popperJS) return;
      this.popperJS.destroy();
      this.popperJS = null;
    },
  },

  beforeCreate() {
    if (!createPopper) {
      if (createPopperPromise) {
        createPopperPromise.then((_popper) => {
          createPopper = _popper.createPopper;
        });
      } else {
        createPopperPromise = import('@popperjs/core').then((_popper) => {
          createPopper = _popper.createPopper;
          return _popper;
        });
      }
    }
  },

  beforeDestroy() {
    this.doDestroy();
    if (this.popperElm && this.popperElm.parentNode === document.body) {
      this.popperElm.removeEventListener('click', stop);
      document.body.removeChild(this.popperElm);
    }
  },
};

export default function throttle(fn, delay) {
  let timer;

  return (...args) => {
    const context = this;

    if (timer) return;

    timer = setTimeout(() => {
      fn.apply(context, args);
      timer = null;
    }, delay);
  };
}

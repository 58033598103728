import LocalStorage from '@/utils/local-storage';
import { TestHosts } from '@/enums';

const mockHost = '/mock';

const testHostKey = LocalStorage.get('test-host') || 0;
const testHost = process.env.NODE_ENV === 'production'
  ? TestHosts.find((i) => i.key === testHostKey).host
  : '/test';

export const host = process.env.VUE_APP_DEPLOY_ENV === 'prod'
  ? process.env.VUE_APP_APIHOST : testHost;

export function generateUrl(path, ifmock) {
  // 如果是打包发布，禁用所有模拟接口
  if (process.env.NODE_ENV === 'production') {
    ifmock = false; // eslint-disable-line
  }
  return (ifmock ? mockHost : host) + path;
}

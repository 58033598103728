<template>
  <div
    class="w-menu-item"
    :class="{
      [`w-menu-item--${type}`]: type,
      'is-disabled': disabled,
      'is-interactive': interactive,
    }"
    @click="handleClick">
    <div class="w-menu-item__content">
      <w-icon v-if="icon" class="w-menu-item__icon" :name="icon" :size="20" />
      <p v-if="label" class="w-menu-item__label">{{ label }}</p>
      <div class="w-menu-item__more">
        <slot></slot>
      </div>
    </div>
    <slot name="desc"></slot>
  </div>
</template>

<script>
export default {
  provide() {
    return {
      form: this,
    };
  },
  props: {
    icon: String,
    label: String,
    type: {
      type: String,
      default: 'normal',
    },
    interactive: {
      type: Boolean,
      default: true,
    },
    closePopover: Boolean,
    disabled: Boolean,
  },
  methods: {
    handleClick() {
      if (this.disabled) return;
      this.$emit('click');

      if (this.closePopover) {
        let temp = this;
        while (temp.$parent) {
          const cur = temp.$parent;
          if (cur.$options.name === 'w-popover') {
            cur.hidePopover();
            break;
          } else {
            temp = cur;
          }
        }
      }
    },
  },
};
</script>

<template>
  <div
    class="w-textarea"
    :class="[
      {
        'w-textarea--count': showLimit,
        'is-disabled': textareaDisabled,
        'is-reating': textareaReating,
        'is-error': textareaError,
        'has-prefix': $slots.prefix,
      },
    ]">
    <div v-if="$slots.prefix"
      class="w-textarea__prefix"
      @mousedown.prevent>
      <slot name="prefix"></slot>
    </div>
    <textarea
      v-if="!textareaReating"
      ref="input"
      v-model="val"
      class="w-textarea__inner"
      :style="{
        height:autoHeight?height:'',
        maxHeight:autoHeight?(maxHeight+'px'):'',
      }"
      :placeholder="placeholder"
      :disabled="textareaDisabled"
      :rows="rows"
      @paste="handlePaste"
      @input="handleInput"
      @focus="handleFocus"
      @blur="handleBlur"
      @compositionstart="isCompositing = true"
      @compositionend="isCompositing = false"></textarea>
    <div v-else class="w-textarea__inner">{{ val }}</div>
    <div
      v-if="showLimit"
      class="w-textarea__count"
      :class="[countError ? 'w-textarea__count--overflow' : '']">
      <span>{{ curLength }}</span>/{{ maxLength }}
    </div>
    <div v-else-if="showActions"
      class="w-textarea__actions"
      @mousedown.prevent>
      <slot></slot>
    </div>
    <w-icon
      v-if="resize !== 'none'"
      class="w-textarea__resize"
      :class="[resizeCursorDown ? 'is-grabbing' : '']"
      name="icon_boxresize" :size="8"
      @mousedown.native="handleResizeMousedown" />
  </div>
</template>

<script>
import { gbLen } from '../../utils';

export default {
  inject: {
    theme: {
      default: 'light',
    },
    form: {
      default: {},
    },
  },
  props: {
    value: String,
    disabled: Boolean,
    reating: Boolean,
    error: Boolean,
    placeholder: String,
    rows: Number,
    showLimit: Boolean,
    showActions: Boolean,
    maxLength: Number,
    resize: {
      type: String,
      default: 'none',
      validator(value) {
        return ['none', 'vertical'].indexOf(value) !== -1;
      },
    },
    autoHeight: Boolean,
    maxHeight: Number,
  },
  data() {
    return {
      curLength: gbLen(this.value),
      countError: false,

      isPaste: false,
      isCompositing: false,

      resizeCursorDown: false,
      startHeight: 0,
      startPoint: 0,
      dir: 0,

      height: 'auto',
    };
  },
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    textareaDisabled() {
      return this.disabled || this.form.disabled;
    },
    textareaReating() {
      return this.reating || this.form.reating;
    },
    textareaError() {
      return this.error;
    },
    // 针对ios12键盘不回弹兼容
    isOldiOS() {
      const u = navigator.userAgent?.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) ?? false;
      if (u) {
        const osVersion = navigator.userAgent.match(/iPhone\sOS\s([\d_]+)/i) || [];
        const osArr = osVersion.length >= 1 ? osVersion[1].split('_') : [];
        const oldOS = osArr.length >= 2 && (osArr[0] < 13);
        return oldOS;
      }
      return false;
    },
  },
  watch: {
    val(value) {
      this.curLength = gbLen(value);
      if (this.autoHeight) {
        this.height = 'auto';
        this.$nextTick(() => {
          this.height = `${this.$refs.input.scrollHeight}px`;
        });
      }
    },
  },
  mounted() {
    this.minHeight = this.$el.offsetHeight;
    if (this.autoHeight) {
      this.height = 'auto';
      this.$nextTick(() => {
        this.height = `${this.$refs.input.scrollHeight}px`;
      });
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    blur() {
      this.$refs.input.blur();
    },
    handleInput(e) {
      if (this.isCompositing) return;
      this.countError = false;
      let _value = this.val; // 缓存之前的值
      const { value } = e.target;
      if (this.maxLength) {
        const len = gbLen(value);
        if (len > this.maxLength) {
          if (this.isPaste) {
            _value = value.slice(0, this.maxLength);
            this.isPaste = false;
          }
          this.val = _value;
          this.$refs.input.value = _value;
          this.countError = true;
          this.$emit('limit');
        }
      }
    },
    handlePaste(e) {
      this.isPaste = true;
      this.$emit('paste', e);
    },
    handleFocus(e) {
      this.$emit('focus', e);
    },
    handleBlur(e) {
      this.$emit('blur', e);
      if (this.isOldiOS) {
        setTimeout(() => {
          this.handleBlurInOldiOS();
        });
      }
    },

    // resize相关
    getPointer(e) {
      const offsetX = e.type.indexOf('touch') !== -1 ? e.touches[0].clientX : e.clientX;
      const offsetY = e.type.indexOf('touch') !== -1 ? e.touches[0].clientY : e.clientY;
      return [offsetX, offsetY];
    },
    startDrag(e) {
      e.stopImmediatePropagation();
      this.resizeCursorDown = true;

      document.addEventListener('mousemove', this.mouseMoveDocumentHandler);
      document.addEventListener('mouseup', this.mouseUpDocumentHandler);
      document.onselectstart = () => false;
    },
    handleResizeMousedown(e) {
      e.preventDefault();
      this.startHeight = this.$el.offsetHeight;
      this.startDrag(e);
      this.dir = this.resize === 'vertical' ? 1 : 0;
      this.startPoint = this.getPointer(e)[this.dir];
    },
    mouseMoveDocumentHandler(e) {
      if (this.resizeCursorDown === false) return;
      const curPoint = this.getPointer(e)[this.dir];
      const curHeight = this.startHeight + curPoint - this.startPoint;
      const height = curHeight < this.minHeight ? this.minHeight : curHeight;
      this.$el.style.height = `${height}px`;
    },
    mouseUpDocumentHandler() {
      this.resizeCursorDown = false;
      this.dir = 0;
      document.removeEventListener('mousemove', this.mouseMoveDocumentHandler);
      document.removeEventListener('mouseup', this.mouseUpDocumentHandler);
      document.onselectstart = null;
    },
    // 针对ios12键盘不回弹兼容
    handleBlurInOldiOS() {
      const timer = setInterval(() => {
        window.scrollTo(0, 0);
        clearInterval(timer);
      }, 1);
    },
  },
};
</script>

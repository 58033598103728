export const HelperCond = {
  charge: { Charge: 'charge' },
  withdraw: { Withdraw: 'withdraw' },
  buy: { Buy: 'buy' },
  sell: { Sell: 'sell' },
  bind: { Login: 'bind' },
  login: { Login: 'login' },
  afterSale: { AfterSale: 'applyforaftersale' },
  AISupport: { AISupport: 'aisupport' },
  commonquestion: { commonquestion: 'commonquestion' },
};

export const DefaultHelperCond = HelperCond.charge;

export const DefaultHelperCondC2C = HelperCond.AISupport;
export const DefaultHelperCondC2CQuestion = HelperCond.commonquestion;

export const RouteHelperCondMap = {
  Market: HelperCond.buy,
  'Market.All': HelperCond.buy,
  'Market.Goods': HelperCond.buy,
  'Market.Inventory': HelperCond.sell,
  'Market.Buy': HelperCond.buy,
  'Market.Buy.History': HelperCond.buy,
  'Market.Sell': HelperCond.sell,
  'Market.Sell.Selling': HelperCond.sell,
  'Market.Sell.History': HelperCond.sell,
  'Market.Account.MyBalance': HelperCond.charge,
  'Market.Account.MyBalance.Deposit': HelperCond.charge,
  'Market.Account.MyBalance.Withdraw': HelperCond.withdraw,
  'Market.Account.MyBalance.History': HelperCond.charge,
  'Market.Account.MyFavorites': HelperCond.buy,
  'Market.UserStore': HelperCond.sell,
  'Market.UserStore.Selling': HelperCond.sell,
  'Market.UserStore.History': HelperCond.sell,
  'Market.UserStore.Gallery': HelperCond.sell,
  'Account.Buy.Order': HelperCond.afterSale,
  'Account.Buy': HelperCond.afterSale,
};

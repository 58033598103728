<template>
  <div class="c-scrollbar"
    :class="{
      'c-scrollbar__drag': cursorDown,
    }"
    @touchstart="handStart">
    <div
      ref="wrap"
      class="c-scrollbar__wrap"
      :class="wrapClass"
      :style="wrapStyle"
      @scroll="handleScroll">
      <div ref="resize" class="c-scrollbar__content">
        <slot></slot>
      </div>
    </div>
    <!-- <div class="c-scrollbar__bar vertical"
      v-if="thumbLenWidth">
      <div class="c-scrollbar__thumb" :style="barVerStyle"
        @mousedown="handleClickThumb($event, 'vertical')"></div>
    </div> -->
    <div v-if="thumbLenHeight"
      class="c-scrollbar__bar horizontal"
      :class="{prevent: preventBar}">
      <div
        class="c-scrollbar__thumb"
        :class="[cursorDown ? 'is-grabbing' : '']"
        :style="barHorStyle"
        @mousedown="handleClickThumb($event, 'horizontal')"></div>
    </div>
  </div>
</template>

<script>
import scrollbarWidth from '@/utils/scrollbar-width';
import { addResizeListener, removeResizeListener } from '@/utils/resize-events';
import EaseFnEnum from '@/utils/ease-fn';

const MINIMUM_SCROLLBAR_LEN = 20;

export default {
  props: {
    wrapClass: String,
    autoHeight: Boolean,
    scrollX: Boolean,
    preventBar: Boolean,
  },
  data() {
    return {
      width: 0,
      height: 0,
      scrollLeft: 0,
      scrollTop: 0,
      thumbLenWidth: 0,
      thumbLenHeight: 0,

      moveX: 0,
      moveY: 0,

      cursorDown: false,
      startOffset: 0,
      startPoint: 0,

      updateFn: null,
    };
  },
  computed: {
    wrap() {
      return this.$refs.wrap;
    },
    wrapStyle() {
      const width = scrollbarWidth();
      const widthStr = `-${width}px`;
      const scrollX = this.scrollX ? 'scroll' : 'hidden';
      return {
        marginRight: widthStr,
        overflowX: scrollX,
      };
    },
    barVerStyle() {
      return {
        transform: `translateX(${this.moveX}px)`,
        width: `${this.thumbLenWidth}px`,
      };
    },
    barHorStyle() {
      return {
        transform: `translateY(${this.moveY}px)`,
        height: `${this.thumbLenHeight}px`,
      };
    },
  },
  mounted() {
    this.$nextTick(this.update);
    this.updateFn = this.autoHeight
      ? () => {
        const { scrollTop } = this;
        this.$el.style.height = '';
        this.$nextTick(() => {
          this.$el.style.height = `${this.$el.clientHeight}px`;
          this.scrollTo(0, scrollTop);
          this.update();
        });
      }
      : this.update;
    addResizeListener(this.$refs.resize, this.updateFn);
    addResizeListener(this.$refs.wrap, this.updateFn);
    if (this.autoHeight) {
      window.addEventListener('resize', this.updateFn);
    }
  },
  beforeDestroy() {
    removeResizeListener(this.$refs.resize, this.updateFn);
    removeResizeListener(this.$refs.wrap, this.updateFn);
    if (this.autoHeight) {
      window.removeEventListener('resize', this.updateFn);
    }
  },
  methods: {
    getPointer(e) {
      const offsetX = e.type.indexOf('touch') !== -1 ? e.touches[0].clientX : e.clientX;
      const offsetY = e.type.indexOf('touch') !== -1 ? e.touches[0].clientY : e.clientY;
      return [offsetX, offsetY];
    },
    handleScroll(e) {
      const { wrap } = this;

      const {
        scrollTop, scrollHeight, clientHeight,
        scrollLeft, scrollWidth, clientWidth,
      } = wrap;

      this.scrollLeft = scrollLeft;
      this.scrollTop = scrollTop;

      this.moveY = ((clientHeight - this.thumbLenHeight) / (scrollHeight - clientHeight))
        * scrollTop;
      this.moveX = ((clientWidth - this.thumbLenWidth) / (scrollWidth - clientWidth))
        * scrollLeft;

      this.$emit('scroll', {
        e,
        scrollTop,
        scrollHeight,
        clientHeight,
        scrollLeft,
        scrollWidth,
        clientWidth,
      });
    },
    /**
     * @function handleStart 触控时触发当前scroll滚动一下，激活当前滚动区域，避免全局滚动区域滚动到边界进入iOS safari的橡皮筋效果。
    */
    handStart() {
      const top = (this.scrollTop || 0) - 1;
      this.$refs.wrap.scrollTo(0, top);
    },
    update() {
      const { wrap } = this;
      if (!wrap) return;
      const {
        scrollTop, scrollHeight, scrollWidth,
        scrollLeft, clientHeight, clientWidth,
      } = wrap;

      this.width = clientWidth;
      this.height = clientHeight;
      this.scrollLeft = scrollLeft;
      this.scrollTop = scrollTop;

      const thumbLenWidth = scrollWidth === 0 ? 0
        : (clientWidth / scrollWidth) * clientWidth;
      const thumbLenHeight = scrollHeight === 0 ? 0
        : (clientHeight / scrollHeight) * clientHeight;

      let horizontalState = true;
      let verticalState = true;

      if (thumbLenWidth < MINIMUM_SCROLLBAR_LEN) {
        this.thumbLenWidth = MINIMUM_SCROLLBAR_LEN;
      } else if (thumbLenWidth >= clientWidth) {
        this.thumbLenWidth = 0;
        verticalState = false;
      } else {
        this.thumbLenWidth = thumbLenWidth;
      }
      if (thumbLenHeight < MINIMUM_SCROLLBAR_LEN) {
        this.thumbLenHeight = MINIMUM_SCROLLBAR_LEN;
      } else if (thumbLenHeight >= clientHeight) {
        this.thumbLenHeight = 0;
        horizontalState = false;
      } else {
        this.thumbLenHeight = thumbLenHeight;
      }
      this.moveY = ((clientHeight - this.thumbLenHeight) / (scrollHeight - clientHeight))
        * scrollTop;
      this.moveX = ((clientWidth - this.thumbLenWidth) / (scrollWidth - clientWidth))
        * scrollLeft;

      this.$emit('update', { v: verticalState, h: horizontalState });
    },
    handleClickThumb(e, axis) {
      this.startDrag(e, axis);
      this.dir = axis === 'horizontal' ? 1 : 0;
      this.startOffset = axis === 'horizontal' ? this.moveY : this.moveX;
      this.startPoint = this.getPointer(e)[this.dir];
    },
    startDrag(e) {
      e.stopImmediatePropagation();
      this.cursorDown = true;

      document.addEventListener('mousemove', this.mouseMoveDocumentHandler);
      document.addEventListener('mouseup', this.mouseUpDocumentHandler);
      document.onselectstart = () => false;
    },
    mouseMoveDocumentHandler(e) {
      if (this.cursorDown === false) return;
      const {
        clientHeight, clientWidth, scrollHeight, scrollWidth,
      } = this.wrap;
      const maxLen = [clientWidth, clientHeight];
      const totalLen = [scrollWidth, scrollHeight];
      let thumbLen = (maxLen[this.dir] / totalLen[this.dir]) * maxLen[this.dir];
      thumbLen = thumbLen < MINIMUM_SCROLLBAR_LEN ? MINIMUM_SCROLLBAR_LEN : thumbLen;
      const maxMoveLen = maxLen[this.dir] - thumbLen;

      const moveOffset = this.getPointer(e)[this.dir] - this.startPoint;
      let currentPos;
      if (moveOffset + this.startOffset < 0) {
        currentPos = 0;
      } else if (moveOffset + this.startOffset > maxMoveLen) {
        currentPos = maxMoveLen;
      } else {
        currentPos = moveOffset + this.startOffset;
      }

      const scrollOffset = ((totalLen[this.dir] - maxLen[this.dir]) / maxMoveLen) * currentPos;
      this.wrap.scrollTop = scrollOffset;
    },
    mouseUpDocumentHandler() {
      this.cursorDown = false;
      this.dir = 0;
      document.removeEventListener('mousemove', this.mouseMoveDocumentHandler);
      document.removeEventListener('mouseup', this.mouseUpDocumentHandler);
      document.onselectstart = null;
    },

    scrollTo(scrollLeft, scrollTop, conf = {}) {
      const { ease, duration = 400 } = conf;
      if (!ease) { // Without smooth animation
        this.wrap.scrollTop = scrollTop;
        this.wrap.scrollLeft = scrollLeft;
      } else { // With easing-fn animation
        let easingFn;
        switch (Object.prototype.toString.call(ease)) {
          case '[object String]':
            easingFn = EaseFnEnum[ease] || EaseFnEnum.linear; break;
          case '[object Boolean]':
            easingFn = EaseFnEnum.linear; break;
          case '[object Function]':
            easingFn = ease; break;
          default:
            easingFn = EaseFnEnum.linear;
        }

        // Time-points count (60fps)
        const count = Math.floor((duration * 60) / 1000);

        // Get current pos & target pos
        const { scrollTop: curTop, scrollLeft: curLeft } = this.wrap;
        const targetTop = (scrollTop !== undefined) ? scrollTop : curTop;
        const targetLeft = (scrollLeft !== undefined) ? scrollLeft : curLeft;
        const positions = new Array(count + 1);
        // calc each timing pos
        for (let i = 0; i < count + 1; i += 1) {
          const curTime = i * (duration / count);
          positions[i] = [
            easingFn(curTime, curTop, targetTop - curTop, duration),
            easingFn(curTime, curLeft, targetLeft - curLeft, duration),
          ];
        }
        this.easeScrollTo(positions);
      }
    },

    // Use requestAnimationFrame to create smooth scroll animation
    easeScrollTo(positions) {
      let scrolling = true; // Interrup animation flag
      let index = 0; // Current step index
      const len = positions.length;
      const scroll = () => {
        if (!scrolling) return;
        if (index >= len) return;
        const [top, left] = positions[index];
        this.scrollTo(left, top);
        index += 1;
        requestAnimationFrame(scroll);
      };
      scroll();
      // Run this fn if need to interrup animation
      return function interrup() {
        scrolling = false;
      };
    },
  },
};
</script>

<style lang="less" scoped>
.c-scrollbar {
  overflow: hidden;
  position: relative;

  &__bar {
    opacity: 0.01;
    &.prevent {
      pointer-events: none;
    }
  }

  &:hover > &__bar,
  &__drag > &__bar {
    opacity: 1;
  }
  &__wrap {
    overflow: hidden scroll;
    -webkit-overflow-scrolling: touch;
    height: 100%;
  }
  &__bar {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    border-radius: 2px;
    transition: opacity 0.12s ease-out;
    &.vertical {
      height: 4px;
      left: 0;
      bottom: 2px;
      > div {
        height: 100%;
      }
    }
    &.horizontal {
      width: 4px;
      top: 0;
      right: 2px;
      > div {
        width: 100%;
      }
    }
  }
  &__thumb {
    border-radius: 2px;
  }
  &__thumb {
    background-color: fade(@tint_light_3, 40%);
    &:hover,
    &.is-grabbing {
      background-color: fade(@tint_light_3, 80%);
    }
  }
}
</style>

import { generateUrl as generateUrl2 } from './utils';

export default {
  // getMarketInventory() {
  //   return generateUrl('/api/market/steam_inventory', false);
  // },
  getMarketPartnerInventory() {
    return generateUrl2('/api/market/partner_inventory', false);
  },
};

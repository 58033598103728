function obj2str(obj = {}) {
  const keys = Object.keys(obj);
  const arr = [];
  keys.map((key) => {
    const value = obj[key];
    if (value !== null && typeof (value) !== 'undefined') {
      arr.push(`${key}=${value}`);
    }
    return true;
  });
  return arr.join('&');
}

export function buildSortedURL(url, params = {}, headers = {}) {
  return `${url}|${obj2str(params)}|${obj2str(headers)}`;
}

export function filetrKeys(obj = {}, includeKeys = []) {
  const keys = Object.keys(obj);
  const newObject = {};
  if (keys.length && includeKeys.length) {
    keys.map((key) => {
      if (includeKeys.includes(key)) {
        newObject[key] = obj[key];
      }
      return true;
    });
  }
  return newObject;
}

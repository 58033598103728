import formItem from './src/form-item.vue';
import formItemGroup from './src/form-item-group.vue';

const form = {};

form.install = (VueInstance) => {
  VueInstance.component('w-form-item', formItem);
  VueInstance.component('w-form-item-group', formItemGroup);
};

export default form;

<template>
  <div class="w-select-sub-dropdown" :style="style">
    <w-scrollbar
      wrap-class="w-select-dropdown__wrap"
      auto-height style="max-height: 100vh;">
      <template v-for="(option, index) in options">
        <div
          v-if="option.key !== undefined"
          :key="option.key"
          class="w-select-dropdown__item"
          @click="handleSelect(option)">
          <slot
            v-if="$scopedSlots.option"
            name="option"
            :option="option"></slot>
          <template v-else>{{ option.label }}</template>
        </div>
        <div
          v-else-if="option.label"
          :key="option.label"
          class="w-select-dropdown__label">
          <slot v-if="$scopedSlots.label" name="label" :option="option"></slot>
          <template v-else>{{ option.label }}</template>
        </div>
        <hr v-else :key="`hr-${index}`" class="w-select-dropdown__hr" />
      </template>
    </w-scrollbar>
  </div>
</template>

<script>
import Popper from '../../popper';

export default {
  name: 'w-select-sub-dropdown',
  mixins: [Popper],
  props: {
    options: {
      type: Array,
      default() { return []; },
    },
  },
  data() {
    return {
      width: 0,
      minWidth: 0,
    };
  },
  computed: {
    style() {
      if (this.width) {
        return { width: `${this.width}px` };
      }
      return { minWidth: `${this.minWidth}px` };
    },
  },
  mounted() {
    // eslint-disable-next-line
    this.popperElm = this.$el;
    this.currentPlacement = 'right-start';
    this.currentPopperModifiers = {
      // flip: {
      //   fallbackPlacements: ['top'],
      //   boundary: document.querySelector('#app'),
      // },
      preventOverflow: {
        altAxis: true,
        boundary: document.querySelector('#app'),
      },
    };
    this.updatePopper();
  },
  methods: {
    handleSelect(option) {
      this.$emit('select', option);
    },
  },
};
</script>

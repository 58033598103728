// import { generateUrl } from './utils';

export default {
  // getMarketSendedList() {
  //   return generateUrl('/api/market/buy_order/sent_bargain', false);
  // },
  // getMarketReceivedList() {
  //   return generateUrl('/api/market/sell_order/received_bargain', false);
  // },
  // postMarketAcceptBargain() {
  //   return generateUrl('/api/market/buyer_bargain/accept', false);
  // },
  // postMarketRejectBargain() {
  //   return generateUrl('/api/market/buyer_bargain/reject', false);
  // },
  // postMarketCancelBargain() {
  //   return generateUrl('/api/market/buyer_bargain/cancel', false);
  // },

  // 购买 还价
  // getMarketBargainPreview() {
  //   return generateUrl('/api/market/buyer_bargain/create/preview', false);
  // },
  // postMarketBargain() {
  //   return generateUrl('/api/market/buyer_bargain/create', false);
  // },
};

import { generateUrl } from './utils';

export default {
  // 获取玩家秀
  getPreviewList() {
    return generateUrl('/api/account/preview/list', false);
  },
  // 检查栏目是否有新信息
  getPreviewCheckColumnNew() {
    return generateUrl('/api/account/check_column_new', false);
  },
  // 上传玩家秀
  postPreviewCreate() {
    return generateUrl('/api/account/preview/add', false);
  },
  // 修改玩家秀内容
  postChangePreviewContent() {
    return generateUrl('/api/market/preview/change_content', false);
  },
  // 修改玩家秀状态
  postChangePreviewState() {
    return generateUrl('/api/market/preview/change_state', false);
  },
  // 玩家秀点赞
  postUpPreview() {
    return generateUrl('/api/account/preview/up', false);
  },
  // 取消玩家秀点赞
  postCancelUpPreview() {
    return generateUrl('/api/market/preview/cancel/up', false);
  },
  // 获取玩家秀详情
  getPreviewDetail() {
    return generateUrl('/api/market/preview/detail', false);
  },
  // 我的玩家秀列表
  getMyPreviewList() {
    return generateUrl('/api/market/preview/my', false);
  },
  postDeletePreview() {
    return generateUrl('/api/account/preview/del', false);
  },
  // 获取评论列表
  getCommentList() {
    return generateUrl('/api/account/comment/list', false);
  },
  // 获取回复列表
  getCommentReplyList() {
    return generateUrl('/api/account/comment/sub_list', false);
  },
  // 发表评论
  postAddComment() {
    return generateUrl('/api/account/comment/add', false);
  },
  // 删除评论
  postDeleteComment() {
    return generateUrl('/api/account/comment/del', false);
  },
  // (取消)点赞评论
  postUpComment() {
    return generateUrl('/api/account/comment/up', false);
  },
  // 发表评论
  postAddCommentReply() {
    return generateUrl('/api/comment/add/reply/', false);
  },
  // 删除评论
  postDeleteCommentReply() {
    return generateUrl('/api/comment/del/reply/', false);
  },
  // (取消)点赞评论
  postUpCommentReply() {
    return generateUrl('/api/comment/up/reply/', false);
  },
  // 获取我的评论
  getMyCommentList() {
    return generateUrl('/api/comment/my/', false);
  },
  // 举报
  postAddReport() {
    return generateUrl('/api/report/add/', false);
  },
};

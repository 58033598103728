import ua from '@/utils/ua';
import native from '@/utils/native.bridge';

const isClient = ua.os.client;
let timer;
let events = [];

class Logger {
  constructor(conf = {}) {
    this.conf = conf;
    this.inWeb = this.conf.inWeb;
  }

  // eslint-disable-next-line
  login(uid) {
    window.sensors?.login(uid);
    window.fcountGtag('login', uid);
    window.gtag('config', 'G-KMQ171GYJM', { user_id: uid });
  }

  // eslint-disable-next-line
  logout() {
    window.sensors?.logout();
    window.fcountGtag('logout');
    window.gtag('config', 'G-KMQ171GYJM', { user_id: '' });
  }
  // eslint-disable-next-line
  fcountPush() {
    window.fcountDataLayer.map((args) => {
      const argsArray = [...args];
      const event = argsArray.shift();
      if (event in window.FCount) {
        window.FCount[event](...argsArray);
      }
      return true;
    });
    window.fcountDataLayer = [];
  }
  // eslint-disable-next-line
  log(event, payloads = {}) {
    if (process.env.VUE_APP_DEPLOY_ENV !== 'prod') {
      console.log(+new Date(), event, payloads);
    }
    window.gtag('event', event, payloads);
    // 网页埋点或者地$native数据
    if (this.inWeb) {
      window.sensors?.track(event, payloads);
      // 聚合一个事件循环内所有需要提交的事件
      // 统一调用 FCount 的批量上传方法
      events.push({ event, ...payloads });
      // 未加载到FCount，不做任何处理，事件存储起来,待加载FCount后统一发送
      if (!window.FCount) {
        window.fcountGtag('logEvents', events);
        events = [];
        return;
      }
      // 加载到Fcount时，处理一下之前遗留的事件处理
      if (window.fcountDataLayer.length) {
        this.fcountPush();
      }
      if (!timer) {
        timer = setTimeout(() => {
          window.FCount.logEvents(events);
          events = [];
          timer = null;
        });
      }
    // 客户端埋点
    } else {
      const keys = Object.keys(payloads);
      native('analytics', {
        event,
        event_params: keys.length ? payloads : null,
      });
    }
  }
}

export const logger = new Logger({
  fcount: [process.env.VUE_APP_FCOUNT],
  inWeb: !isClient,
});

const plugin = {};
plugin.install = (VueInstance) => {
  VueInstance.prototype.$logger = logger.log.bind(logger);
};

export default plugin;

<template>
  <w-modal
    class="w-dialog__wrapper"
    :show="show"
    :close-on-click-modal="false"
    @close="handleClose">
    <div class="w-dialog__outer" :style="mergedWrapStyle">
      <w-button v-if="countdownTime === 0 && needCloseIcon"
        class="w-dialog__close"
        flat icon name="icon_close" type="bg1"
        :icon-size="24"
        @click="handleClose" />
      <div
        class="w-dialog"
        :class="{
          'w-dialog--without-btn': !withBtn,
          'w-dialog--without-title': !title,
          'w-dialog--maximized': maximized,
          'is-customized': customized,
          [wrapClass]: wrapClass,
          [(theme ? 'w-dialog--' + theme : '')]: theme,
        }">
        <div
          v-if="title || $slots.headerSlot"
          class="w-dialog__header">
          <h1>{{ title }}</h1>
          <slot name="headerSlot"></slot>
        </div>

        <w-scrollbar class="w-dialog__content-wrap">
          <div class="w-dialog__content"><slot></slot></div>
          <div v-if="tips" class="w-dialog__tips">{{ tips }}</div>
        </w-scrollbar>

        <div v-if="withBtn" class="w-dialog__actions" :class="{
          [(theme ? 'w-dialog__actions--' + theme : '')]: theme,
        }">
          <w-button
            v-if="needCancelBtn"
            style="width: auto;"
            :loading="loading"
            :disabled="countdownTime > 0"
            plain
            @click="handleCancel">{{ formatCancelText }}
            <template v-if="countdownTime > 0">({{ countdownTime }})</template>
          </w-button>
          <w-button
            v-if="needConfirmBtn"
            style="width: auto;"
            :type="confirmType"
            :loading="loading"
            :disabled="disabled || countdownTime > 0"
            @click="handleConfirm">{{ formatConfirmText }}
            <template v-if="countdownTime > 0">({{ countdownTime }})</template>
          </w-button>
        </div>
      </div>
    </div>
  </w-modal>
</template>

<script>
import { isObject } from '../../utils';

export default {
  props: {
    title: String,
    tips: String,
    show: Boolean,
    maximized: Boolean,
    wrapClass: String,
    theme: String,
    wrapStyle: {
      type: [String, Object],
      default() { return {}; },
    },
    customized: Boolean,
    width: Number,
    maxHeight: Number,
    needCloseIcon: {
      type: Boolean,
      default: true,
    },
    needConfirmBtn: {
      type: Boolean,
      default: true,
    },
    needCancelBtn: {
      type: Boolean,
      default: true,
    },
    confirmText: {
      type: String,
      default: '',
    },
    cancelText: {
      type: String,
      default: '',
    },
    confirmType: {
      type: String,
      default: 'primary',
    },
    loading: Boolean,
    disabled: Boolean,
    countdown: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      countdownTime: 0,
    };
  },
  computed: {
    formatCancelText() {
      return this.cancelText || this.$t('global.cancel');
    },
    formatConfirmText() {
      return this.confirmText || this.$t('global.confirm');
    },
    withBtn() {
      return !this.customized && (this.needConfirmBtn || this.needCancelBtn);
    },
    mergedWrapStyle() {
      if (isObject(this.wrapStyle)) {
        return {
          ...this.wrapStyle,
          width: `${this.width}px`,
          maxHeight: `${this.maxHeight}px`,
        };
      }
      return `${this.wrapStyle};width:${this.width}px;max-height:${this.maxHeight}px;`;
    },
  },
  watch: {
    show(val) {
      if (val && this.countdown > 0) {
        this.countdownTime = this.countdown;
        const time = window.setInterval(() => {
          this.countdownTime -= 1;
          if (this.countdownTime === 0) {
            window.clearInterval(time);
          }
        }, 1 * 1e3);
      }
    },
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
    async handleConfirm() {
      if (!this.$parent) {
        const { submit } = this.$slots.default[0].componentInstance;
        let resp;
        if (submit) {
          this.loading = true;
          try {
            resp = await submit();
          } catch (err) {
            this.loading = false;
            return;
          }
          this.loading = false;
        }
        this.$emit('confirm', resp);
        return;
      }
      this.$emit('confirm');
    },
    handleCancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<template>
  <div
    class="w-form-item__wrap"
    :class="[
      !reating && required ? 'w-form-item--required' : '',
      {
        [`w-form-item--${size}`]: size,
      },
    ]">
    <div v-if="title" class="w-form-item__title w-form-item-title-box">
      <div class="title-box-content">
        {{ title }}
        <w-popover v-if="tips"
          style="margin-left: 4px;"
          placement="bottom-start" :offset="[0, 4]">
          <w-button
            slot="reference" type="normal-2"
            style="transform: rotate(180deg);" icon
            name="icon_skinlist_nameicon" :icon-size="16" />
          <div class="w-tooltips--with-title" v-html="tips"></div>
        </w-popover>
        <slot name="dialogTips"></slot>
      </div>
      <slot name="rightTip"></slot>
    </div>
    <div
      class="w-form-item"
      :class="[error ? 'is-error' : '', disabled ? 'is-disabled' : '']">
      <slot class="w-form-item__error"></slot>
    </div>
    <div v-if="error" class="w-form-item--error">{{ errorText }}</div>
  </div>
</template>

<script>

export default {
  provide() {
    return {
      form: this,
    };
  },
  props: {
    title: String,
    required: Boolean,
    errorText: String,
    disabled: Boolean,
    reating: Boolean,
    size: String, // [s,m]
    tips: {
      type: String,
      default: '',
    },
  },
  computed: {
    error() {
      return !!this.errorText;
    },
  },
  methods: {
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('w-modal',{staticClass:"w-dialog__wrapper",attrs:{"show":_vm.show,"close-on-click-modal":false},on:{"close":_vm.handleClose}},[_c('div',{staticClass:"w-dialog__outer",style:(_vm.mergedWrapStyle)},[(_vm.countdownTime === 0 && _vm.needCloseIcon)?_c('w-button',{staticClass:"w-dialog__close",attrs:{"flat":"","icon":"","name":"icon_close","type":"bg1","icon-size":24},on:{"click":_vm.handleClose}}):_vm._e(),_c('div',{staticClass:"w-dialog",class:{
        'w-dialog--without-btn': !_vm.withBtn,
        'w-dialog--without-title': !_vm.title,
        'w-dialog--maximized': _vm.maximized,
        'is-customized': _vm.customized,
        [_vm.wrapClass]: _vm.wrapClass,
        [(_vm.theme ? 'w-dialog--' + _vm.theme : '')]: _vm.theme,
      }},[(_vm.title || _vm.$slots.headerSlot)?_c('div',{staticClass:"w-dialog__header"},[_c('h1',[_vm._v(_vm._s(_vm.title))]),_vm._t("headerSlot")],2):_vm._e(),_c('w-scrollbar',{staticClass:"w-dialog__content-wrap"},[_c('div',{staticClass:"w-dialog__content"},[_vm._t("default")],2),(_vm.tips)?_c('div',{staticClass:"w-dialog__tips"},[_vm._v(_vm._s(_vm.tips))]):_vm._e()]),(_vm.withBtn)?_c('div',{staticClass:"w-dialog__actions",class:{
        [(_vm.theme ? 'w-dialog__actions--' + _vm.theme : '')]: _vm.theme,
      }},[(_vm.needCancelBtn)?_c('w-button',{staticStyle:{"width":"auto"},attrs:{"loading":_vm.loading,"disabled":_vm.countdownTime > 0,"plain":""},on:{"click":_vm.handleCancel}},[_vm._v(_vm._s(_vm.formatCancelText)+" "),(_vm.countdownTime > 0)?[_vm._v("("+_vm._s(_vm.countdownTime)+")")]:_vm._e()],2):_vm._e(),(_vm.needConfirmBtn)?_c('w-button',{staticStyle:{"width":"auto"},attrs:{"type":_vm.confirmType,"loading":_vm.loading,"disabled":_vm.disabled || _vm.countdownTime > 0},on:{"click":_vm.handleConfirm}},[_vm._v(_vm._s(_vm.formatConfirmText)+" "),(_vm.countdownTime > 0)?[_vm._v("("+_vm._s(_vm.countdownTime)+")")]:_vm._e()],2):_vm._e()],1):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
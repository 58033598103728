<template>
  <div class="w-count" :class="[typeCss, {' inFocus': isFoucs }]">
    <w-button class="w-count-btn"
      :disabled="offMinus"
      icon plain type="bg1"
      name="icon_minus" :icon-size="size"
      @click="onMinus" />
    <div class="w-count-input">
      <label>{{ count }}</label>
      <input
        v-model.number="count"
        type="number"
        :min="min"
        :max="max"
        @keydown="onKeydown"
        @focus="onFocus"
        @input="onInput"
        @change="onChange"
        @blur="onBlur" />
    </div>
    <w-button class="w-count-btn"
      :disabled="offPlus"
      icon plain type="bg1"
      name="icon_plus" :icon-size="size"
      @click="onPlus" />
  </div>
</template>

<script>
export default {
  props: {
    type: String,
    iconSize: {
      type: Number,
      default: 12,
    },
    num: {
      type: Number,
      default: 1,
    },
    step: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 1,
    },
    max: Number,
  },
  data() {
    return {
      isFoucs: false,
      count: this.num,
    };
  },
  computed: {
    offMinus() {
      return this.count <= this.min;
    },
    offPlus() {
      return this.max !== undefined && this.count >= this.max;
    },
    typeCss() {
      return this.type ? `w-count-${this.type}` : '';
    },
    size() {
      return this.iconSize;
    },
  },
  watch: {
    num() {
      this.count = this.num;
    },
    count() {
      this.$emit('input', this.count || this.min);
    },
  },
  methods: {
    onKeydown(event) {
      if (event.key === '-') {
        event.preventDefault();
      }
    },
    onInput() {
      const count = parseInt(this.count, 10);
      if (Number.isNaN(count) || count < this.min) {
        this.count = this.min;
        this.$emit('change', this.count);
        return;
      }
      if (count > this.max) {
        this.count = this.max;
        this.$emit('change', this.count);
        return;
      }
      this.count = count;
    },
    onChange() {
      let count = parseInt(this.count, 10);
      if (count % this.step) {
        this.$emit('modify');
        count = Math.floor(this.valid(count) / this.step) * this.step;
        this.count = count;
      }
      this.$emit('change', this.count);
    },
    onFocus() {
      this.isFoucs = true;
      this.$emit('log', { type: 'focus' });
    },
    onBlur() {
      this.count = this.valid(Math.floor(this.count));
      this.isFoucs = false;
      this.$emit('log', { type: 'blur' });
    },
    valid(num) {
      if (num < this.min) {
        return this.min;
      }
      if (this.max && num > this.max) {
        return this.max;
      }
      return num;
    },
    onMinus() {
      if (this.offMinus) {
        return;
      }
      this.count = this.valid(this.count - this.step);
      this.$emit('change', this.count);
      this.$emit('log', { type: 'minus' });
    },
    onPlus() {
      if (this.offPlus) {
        return;
      }
      this.count = this.valid(this.count + this.step);
      this.$emit('change', this.count);
      this.$emit('log', { type: 'plus' });
    },
    reset() {
      this.count = this.min;
    },
  },
};
</script>
<style lang="less" scoped>
.w-count {
  display: flex;
  max-width: 200px;
  &.w-count-primary1 {
    width: 100%;
    height: 40px;
    max-width: unset;
    &>div {
      height: 100%;
      cursor: pointer;
      &.w-count-input {
        flex: 1;
      }
      &.disabled {
        pointer-events: auto;
      }
    }
  }
  &-btn {
    width: 40px!important;
    height: 40px!important;
  }
  &-input {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    margin: 0 4px;
    position: relative;
    text-align: center;
    min-width: 80px;
    width: auto;
    background-color: var(--bg-bg2);
    border: 1px solid var(--bg-bg2);
    border-radius: 4px;
    &:hover {
      border-color: var(--el-bc0);
    }

    &.is-focus,
    &:focus-within {
      border-color: var(--el-bc0);
      background-color: var(--bg-bg1);
    }

    input {
      border: 0;
      background: transparent;
      position: absolute;
      width: 100%;
      height: 100%;
      line-height: 100%;
      text-align: center;
      font-size: 16px;
    }
    label {
      visibility: hidden;
    }
  }
}
</style>

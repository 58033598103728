/** 基于https://github.com/dirkbonhomme/js-cache/tree/1.0.3
 */
/**
 * Find out if an object has a certain property
 *
 * @param {object} object
 * @param {string} key
 */
function has(object, key) {
  return Object.prototype.hasOwnProperty.call(object, key);
}

/**
 * Cache class
 */
function Cache() {
  this.initialize();
}

const _ = Cache.prototype;

/**
 * Constructor
 *
 * @param {object} external
 */
_.initialize = function initialize() {
  this.store = {};
};

/**
 * Insert or overwrite data
 *
 * @param {string} key
 * @param {mixed} value
 * @param {number} ttl   Time to live in milliseconds (optional)
 */
_.set = function set(key, value, ttl) {
  if (typeof key === 'undefined') throw new Error('Required argument key is undefined');

  const record = { value };
  if (typeof ttl === 'number') {
    record.timeout = +new Date() + ttl;
  }
  console.log(record);
  this.store[key] = record;
};

/**
 * Get cached data
 *
 * @param {string} key
 */
_.get = function get(key) {
  if (typeof key === 'undefined') throw new Error('Required argument key is undefined');
  if (has(this.store, key)) {
    const record = this.store[key];
    if (record.timeout) {
      if (record.timeout < +new Date()) {
        return record.value;
      }
      this.remove(key);
    }
    return record.value;
  }
  return undefined;
};

/**
 * Delete cached data
 *
 * @param {string} key
 * @return {boolean} Returns true if record existed
 */
_.remove = function del(key) {
  if (typeof key === 'undefined') throw new Error('Required argument key is undefined');
  return this.delInternal(key);
};

_.delInternal = function delInternal(key) {
  if (has(this.store, key)) {
    // Delete record
    delete this.store[key];
    return true;
  }
  return false;
};

/**
 * Clear cached data
 *
 * @return {number} Returns number of cleared records
 */
_.clear = function clear() {
  const size = this.size();
  this.store = {};
  return size;
};

/**
 * Retrieve number of records
 *
 * @return {number}
 */
_.size = function size() {
  return this.keys.length;
};

/**
 * Retrieve internal store
 *
 * @return {object}
 */
_.debug = function debug() {
  return this.store;
};

/**
 * Retrieve list of keys
 *
 * @return {string[]}
 */
_.keys = function keys() {
  return Object.keys(this.store);
};

_.removeExpired = function clearExper() {
  const keys = this.keys();
  const now = +new Date();
  const self = this;
  keys.map(((key) => {
    if (has(self.store, key) && self.store[key].timeout < now) {
      self.delInternal(key);
    }
    return true;
  }));
};

export default Cache;

const START_Z_INDEX = 2000;
const MODAL_OFFSET = 8000;

class PopupManager {
  constructor() {
    this.modals = new Set();
    this.zIndex = START_Z_INDEX;
  }

  hasModal() {
    return !!this.modals.size;
  }

  getInstance(id) {
    return this.instances[id];
  }

  register(id) {
    if (id) {
      this.modals.add(id);
    }
  }

  unregister(id) {
    if (id) {
      this.modals.delete(id);
    }
    return this.modals.size;
  }

  nextZIndex() {
    this.zIndex += 1;
    if (this.modals.size) return this.zIndex + MODAL_OFFSET;
    return this.zIndex;
  }
}

export default new PopupManager();

import ua from './ua';

const client = ua.os.client || ua.os.partner || {};

// 无回调函数
const NOTCALLBACK = ['analytics'];
const FN = () => {};

// 客户端接口兼容性检测
const CAMPATIBILITY = {
  request_server_api: {
    android: '1.8.8',
    iOS: '0.0.0',
  },
};

const isCampatible = (version, targetVersion) => {
  const versionArr = version.split('.');
  const targetVersionArr = targetVersion.split('.');
  const len = Math.max(versionArr.length, targetVersionArr.length);
  for (let i = 0; i < len; i += 1) {
    const v = versionArr[i] ? parseInt(versionArr[i], 10) : 0;
    const t = targetVersionArr[i] ? parseInt(targetVersionArr[i], 10) : 0;
    if (v > t) return true;
    if (v < t) return false;
  }
  return true;
};

/**
 * @description: 客户端接口
 * @param {string} api 接口名
 * @param {object} params 接口参数
 * @param {object} config 接口配置
 * @param {string} config.timeout 接口配置-超时时间, 默认：10s; -1表示不设置超时时间，单位为s
 * @param {string} config.hasCallBack  接口配置-有回调函数，默认有回调（排除埋点接口）
 */
function native(api, params = {}, config = {}) {
  if (!client.version || !api) {
    const err = { code: -1, msg: '非客户端接口，不能调客户端接口' };
    return Promise.reject(err);
  }
  if (CAMPATIBILITY[api]) {
    const targetVersion = CAMPATIBILITY[api][client.ios ? 'iOS' : 'android'];
    if (targetVersion && !isCampatible(client.version, targetVersion)) {
      const err = { code: -1, msg: `客户端版本过低，需要 >=${targetVersion} 版本` };
      return Promise.reject(err);
    }
  }
  const key = 'jsBridge';
  const id = (Math.round(Math.random() * 1000000000000).toString(16));
  const successKey = `${key}_success_${id}`;
  const failureKey = `${key}_failure_${id}`;
  const callbackKey = `${key}_callback_${id}`;
  const hasCallBack = config.hasCallBack || (api && !NOTCALLBACK.includes(api));
  const timeout = config.timeout || 10 * 1000;
  let timer = null;
  // 客户端参数处理
  const nativeParams = {
    ...params,
    ...(hasCallBack ? {
      on_success: successKey,
      on_failure: failureKey,
      callback: callbackKey,
    } : {}),
  };
  let _promise = [];
  // 回调promsise链设置
  if (hasCallBack) {
    // 客户端回调promise
    const _callBackPromise = new Promise((resolve, reject) => {
      window[callbackKey] = resolve;
      window[successKey] = resolve;
      window[failureKey] = function onFailure(...args) {
        const arr = Array.prototype.slice.call(args);
        const err = { code: arr[0], msg: arr[1] };
        // 仅传一个参数时，默认code为0，msg为错误信息
        if (arr.length === 1) {
          const index = 0;
          err.code = 0; // 错误类型
          err.msg = arr[index]; // 错误信息
        }
        reject(err);
      };
    });
    // 客户端回调超时promise
    const _timeOutPromise = timeout > 0 ? new Promise((resolve, reject) => {
      timer = setTimeout(() => {
        const err = { code: 408, msg: '超时' };
        reject(err);
      }, timeout);
    }) : null;
    _promise = [..._promise, ...[_callBackPromise, _timeOutPromise].filter((item) => item)];
  } else {
    _promise.push(Promise.resolve());
  }
  const backPromse = Promise.race(_promise).finally(() => {
    if (window[callbackKey]) {
      window[callbackKey] = FN;
    }
    if (window[successKey]) {
      window[successKey] = FN;
    }
    if (window[failureKey]) {
      window[failureKey] = FN;
    }
    clearTimeout(timer);
  });

  // ios客户端接口
  if (client.ios && api) {
    window.webkit.messageHandlers[api].postMessage(JSON.stringify({
      params: nativeParams,
    }));
  // android客户端接口
  } else if (client.android && api) {
    window.prompt(JSON.stringify({
      api,
      domain: ua.os.partner ? 'fc24creator' : 'wagon',
      params: JSON.stringify(nativeParams),
    }));
  }
  return backPromse;
}

export default native;

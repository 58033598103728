import { buildSortedURL, filetrKeys } from './utils';
import localeStorage from '../local-storage';
import Cache from '../js-cache';

// 默认存储5分钟,
const FIVE_MINUTES = 1000 * 60 * 5;

export default function cacheAdapterEnhancer(adapter, options = {}) {
  const {
    enabledByDefault = true,
    // 默认使用localstorage, 支持：localstorage、jscache
    defaultCacheType = 'localstorage',
    defaultCacheTime = FIVE_MINUTES,
    // 缓存的头部key信息
    defaultIncludeHeader = [],
  } = options;

  const jsCache = new Cache();

  return (config) => {
    const {
      url,
      method,
      params,
      headers,
      forceUpdate, // 强制更新
    } = config;
    const useCache = 'useCache' in config ? !!config.useCache : enabledByDefault;
    if (method === 'get' && useCache) {
      const useConfig = typeof config.useCache === 'boolean' ? {} : config.useCache;
      // 缓存时间
      const useCacheTime = Math.abs(useConfig.time || defaultCacheTime);
      // 缓存类型：使用不同的缓存处理
      const useCacheType = (useConfig.cacheType || defaultCacheType).toLowerCase();
      const cachePlugin = useCacheType === 'jscache' ? jsCache : localeStorage;
      // 是否要缓存头部信息
      const useCacheHeader = !useConfig.disableCacheHeaders;
      const includeHeaders = useConfig.includeHeaders || defaultIncludeHeader;
      const useHeaders = useCacheHeader ? filetrKeys(headers, includeHeaders) : {};
      const { _l, ..._params } = params || {};
      // 获取缓存信息
      const index = buildSortedURL(url, _params, useHeaders);
      const response = cachePlugin.get(index);
      let responsePromise = null;
      // 无缓存内容或者强制刷新，获取远程数据
      if (!response || forceUpdate) {
        responsePromise = (async function getRemoteDate() {
          try {
            return await adapter(config).then((res) => {
              cachePlugin.removeExpired();
              cachePlugin.set(index, {
                status: res.status,
                data: res.data,
              }, useCacheTime);
              return res;
            });
          } catch (err) {
            cachePlugin.remove(index);
            throw err;
          }
        }());
      // 返回本地缓存数据
      } else {
        responsePromise = Promise.resolve(response);
      }
      return responsePromise;
    }
    return adapter(config);
  };
}

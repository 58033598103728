import { generateUrl as generateUrl2 } from './utils';

export default {
  getActivityDetail() {
    return generateUrl2('/api/activity/wish/activity_detail', false);
  },
  postVoteShop() {
    return generateUrl2('/api/activity/wish/vote', false);
  },
  getShareRank() {
    return generateUrl2('/api/activity/wish/share_rank', false);
  },
  getVoteHistory() {
    return generateUrl2('/api/activity/wish/vote_show', false);
  },
  postShareActive() {
    return generateUrl2('/api/activity/wish/share', false);
  },
  getVoteLog() {
    return generateUrl2('/api/activity/wish/vote_log', false);
  },
  getShareLog() {
    return generateUrl2('/api/activity/wish/share_log', false);
  },
  getShareList() {
    return generateUrl2('/api/activity/share/activity_list', false);
  },
  getShareDetail() {
    return generateUrl2('/api/activity/share/activity_detail', false);
  },
  postShareToken() {
    return generateUrl2('/api/activity/share/share', false);
  },

  // 抽奖活动
  getActivityDetailEva() {
    return generateUrl2('/api/activity/lottery/activity_info', false);
  },
  postFinishTask() {
    return generateUrl2('/api/activity/lottery/finish_task', false);
  },
  postLottery() {
    return generateUrl2('/api/activity/lottery', false);
  },
  getLotteryHistory() {
    return generateUrl2('/api/activity/lottery/get_history', false);
  },
  getRegCoupon() {
    return generateUrl2('/api/account/get_reg_coupon', false);
  },
};

<template>
  <w-drawer
    :show="show"
    :title="title"
    class="w-select-dropdown w-select-dropdown--modal w-select-dropdown-inner"
    :wrap-class="wrapClass"
    :wrap-style="wrapStyle"
    @close="handleClose">
    <slot></slot>
  </w-drawer>
</template>

<script>

export default {
  props: {
    show: Boolean,
    title: String,
    wrapClass: String,
    wrapStyle: [String, Object],
  },
  methods: {
    handleClose(e) {
      this.$emit('close', e);
    },
  },
};
</script>

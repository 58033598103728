<template>
  <w-modal
    :show="show"
    :mask="mask"
    :z-index="zIndex"
    class="w-drawer"
    @close="handleClose">
    <div
      class="w-drawer-inner"
      :class="{'is-active': active, [wrapClass]: wrapClass }"
      :style="wrapStyle">
      <div class="w-drawer-header" :class="{'with-desc':description}"
        :style="{borderBottom:!headerUnderline && 'none'}">
        <div class="w-drawer-title">{{ title }}</div>
        <div v-if="description" class="w-drawer-desc">{{ description }}</div>
        <slot name="headerSlot"></slot>
      </div>
      <w-button style="top: 6px; height: 48px; line-height: 48px;"
        class="w-drawer-close"
        type="normal-3"
        icon name="icon_close"
        :icon-size="36"
        @click="handleClose()" />
      <slot></slot>
    </div>
  </w-modal>
</template>

<script>

export default {
  props: {
    show: Boolean,
    title: String,
    description: String,
    zIndex: Number,
    mask: {
      type: Boolean,
      default: true,
    },
    wrapClass: String,
    wrapStyle: [String, Object],
    headerUnderline: { // 是否需要头部下方的分割线
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      active: this.show,
    };
  },
  watch: {
    show(val) {
      if (val) {
        setTimeout(() => {
          this.active = true;
        });
      } else {
        this.active = false;
      }
    },
  },
  methods: {
    handleClose(e) {
      this.$emit('close', e);
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'w-input',
    `w-input--${_vm.size}`,
    {
      'is-disabled': _vm.inputDisabled,
      'is-reating': _vm.inputReating,
      'is-error': _vm.inputError,
      'is-focus': _vm.injectFocus,
      'w-input--prefix': _vm.$slots.prefix,
      'w-input--suffix': _vm.$slots.suffix,
      'w-input--button': _vm.$slots.button,
    },
  ]},[(_vm.$slots.prefix)?_c('div',{staticClass:"w-input__prefix"},[_vm._t("prefix")],2):_vm._e(),_c('input',{ref:"input",attrs:{"type":_vm.type,"placeholder":_vm.placeholder,"disabled":_vm.inputDisabled || _vm.inputReating,"readonly":_vm.readonly,"autocomplete":_vm.autocomplete === 'off' ? 'new-password' : ''},domProps:{"value":_vm.value},on:{"paste":_vm.handlePaste,"input":_vm.handleInput,"focus":_vm.handleFocus,"blur":_vm.handleBlur,"compositionstart":_vm.handleCompositionStart,"compositionend":_vm.handleCompositionEnd}}),(!_vm.inputDisabled && _vm.clearable && !!_vm.value)?_c('w-icon',{staticClass:"w-input__clear",attrs:{"name":"icon_home_clearfilter","size":12},on:{"click":_vm.handleClear},nativeOn:{"mousedown":function($event){$event.preventDefault();}}}):_vm._e(),(_vm.showLimit)?_c('div',{staticClass:"w-input__count",class:[_vm.countError ? 'w-input__count--overflow' : '']},[_c('span',[_vm._v(_vm._s(_vm.curLength))]),_vm._v("/"+_vm._s(_vm.maxLength)+" ")]):_vm._e(),_c('div',{staticClass:"w-input__suffix",on:{"mousedown":function($event){$event.preventDefault();}}},[_vm._t("suffix")],2),_c('div',{staticClass:"w-input__button"},[_vm._t("button")],2),_c('div',{staticClass:"w-input__footer"},[_vm._t("footer")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
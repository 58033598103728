const { localStorage } = window;
let hasLocalStorage;
let _storage = {};

try {
  hasLocalStorage = ('localStorage' in window) && localStorage !== null;
  localStorage.setItem('test', 'test');
  localStorage.getItem('test');
  localStorage.removeItem('test');
} catch (e) {
  hasLocalStorage = false;
}

const LocalStorage = {

  get(key) {
    let obj;
    let value;
    if (hasLocalStorage) {
      value = localStorage.getItem(key);
    } else {
      value = _storage[key];
    }
    if (value) {
      try {
        obj = JSON.parse(value);
      } catch (e) { /* */ }
    }
    if (obj && obj.data !== undefined) {
      if (!('expire' in obj) || obj.expire > new Date()) {
        return obj.data;
      }
      this.remove(key);
    }
    return null;
  },

  set(key, value, expire) {
    const obj = { data: value };
    if (expire > 0) {
      obj.expire = +new Date() + expire;
    }
    const str = JSON.stringify(obj);
    if (hasLocalStorage) {
      localStorage.setItem(key, str);
    } else {
      _storage[key] = str;
    }
    return value;
  },

  remove(key) {
    if (hasLocalStorage) {
      localStorage.removeItem(key);
    } else {
      delete _storage[key];
    }
  },

  removeExpired() {
    Object.keys(localStorage).forEach((key) => {
      this.get(key);
    }, LocalStorage);
  },

  clear() {
    if (hasLocalStorage) {
      try {
        window.localStorage.clear();
      } catch (e) {
        _storage = {};
      }
    } else {
      _storage = {};
    }
  },
};

if (hasLocalStorage) {
  LocalStorage.removeExpired();
}

export default LocalStorage;

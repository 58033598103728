import { generateUrl as generateUrl2 } from './utils';

export default {
  // 获取推荐列表
  // getShopFeatured(id) {
  //   return generateUrl(`/api/market/shop/${id}/featured`, false);
  // },
  // 获取在售列表
  // getShopSellOrder(id) {
  //   return generateUrl(`/api/market/shop/${id}/sell_order`, false);
  // },
  // 获取求购列表
  // getShopBuyOrder(id) {
  //   return generateUrl(`/api/market/shop/${id}/buy_order`, false);
  // },
  // 获取成交记录列表
  // getShopBillOrder(id) {
  //   return generateUrl(`/api/market/shop/${id}/bill_order`, false);
  // },
  // 获取成交记录列表
  // putSellOrderFeatured() {
  //   return generateUrl('/api/market/sell_order/featured', false);
  // },
  // 设置店铺信息
  // postShopInfo() {
  //   return generateUrl('/account/api/shop/info', false);
  // },
  getUserStorePopup() {
    return generateUrl2('/api/market/user_store/popup', false);
  },
};

export default {
  props: {
    name: String,
    size: Number,
  },
  data() {
    return {
      svg: '',
    };
  },
  methods: {
    updateSvg() {
      import(
        /* webpackMode: "lazy-once" */
        /* webpackChunkName: "icons" */
        /* webpackPreload: true */
        `@/assets/svg-icons/${this.name}.svg`
      ).then((svg) => {
        this.svg = svg.default;
      });
    },
  },
  created() {
    this.updateSvg();
  },
  watch: {
    name() {
      this.updateSvg();
    },
  },
  render(createElm) {
    if (!this.svg) {
      return createElm('svg', {
        style: {
          width: `${this.size}px`,
          height: `${this.size}px`,
          fill: 'currentColor',
        },
        on: {
          click: (e) => {
            this.$emit('click', e);
          },
        },
      });
    }
    const [, attrs, content] = this.svg.match(/<svg(.*?)>(.*)<\/svg>/);
    const match = attrs.match(/(\w*?)="(.*?)"/g);

    const attrsMap = !match ? {}
      : match.reduce((prev, cur) => {
        const [, key, val] = cur.match(/(.*?)="(.*?)"/) || [];
        // eslint-disable-next-line
        prev[key] = val;
        return prev;
      }, {});
    return createElm('svg', {
      attrs: attrsMap,
      style: {
        width: `${this.size}px`,
        height: `${this.size}px`,
        fill: 'currentColor',
      },
      domProps: {
        innerHTML: content,
      },
      on: {
        click: (e) => {
          this.$emit('click', e);
        },
      },
    });
  },
};

<template>
  <div class="w-form-item-group">
    <slot :error="error"></slot>
  </div>
</template>

<script>
export default {
  props: {
    errorText: String,
  },
  computed: {
    error() {
      return !!this.errorText;
    },
  },
};
</script>

<template>
  <button
    class="w-button"
    :class="[
      type ? 'w-button--' + type : '',
      size ? 'w-button--' + size : '',
      {
        'is-disabled': buttonDisabled,
        'is-loading': loading,
        'is-success': success,
        'is-flat': flat,
        'is-plain': plain,
        'is-icon': icon && !$slots.default,
        'with-icon': icon && $slots.default,
        'is-text': text,
        'is-focus': buttonFocus,
        'is-fill': fill,
        'is-transparent': transparent,
      },
    ]"
    @click="handleClick">
    <w-spinner
      v-if="loading"
      size="s"
      :theme="type"
      class="w-button__state" />
    <!-- <w-la
      v-if="success"
      class="w-button__state"
      name="button_finished_basic"
      :speed="2" :loop="false"
      :width="24" :height="24" /> -->
    <template>
      <w-icon
        v-if="icon"
        :style="{ visibility: loading || success ? 'hidden' : '' }"
        :name="name"
        :size="iconBtnSize" />
      <span
        v-if="$slots.default"
        :style="{ visibility: loading || success ? 'hidden' : '' }">
        <slot></slot>
      </span>
    </template>
  </button>
</template>

<script>
export default {
  name: 'w-button',
  inject: {
    theme: {
      default: 'light',
    },
    form: {
      default: {},
    },
  },
  props: {
    type: { // primary, danger, gray[icon]
      type: String,
      default: 'normal',
    },
    size: { // s, m, l
      type: String,
      default: 's',
    },
    loading: Boolean,
    success: Boolean,
    flat: Boolean,
    plain: Boolean,
    text: Boolean,
    icon: Boolean,
    iconSize: Number,
    name: String,
    disabled: Boolean,
    focus: Boolean,
    fill: Boolean,
    transparent: Boolean,
  },
  data() {
    return {
      injectFocus: false,
    };
  },
  computed: {
    buttonDisabled() {
      return this.loading || this.success || this.disabled || this.form.disabled;
    },
    buttonFocus() {
      return this.focus || this.injectFocus;
    },
    iconBtnSize() {
      if (this.text) return 16;
      if (this.iconSize) return this.iconSize;
      const map = {
        xs: 12, s: 20, m: 24, l: 32,
      };
      return map[this.size];
    },
  },
  methods: {
    handleClick(evt) {
      if (this.buttonDisabled) return;
      this.$emit('click', evt);
    },
  },
};
</script>

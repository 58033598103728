<template>
  <div class="w-select-dropdown" :style="style">
    <slot></slot>
  </div>
</template>

<script>
import Popper from '../../popper';

export default {
  mixins: [Popper],
  data() {
    return {
      width: 0,
      minWidth: 0,
    };
  },
  computed: {
    style() {
      if (this.width) {
        return { width: `${this.width}px` };
      }
      return { minWidth: `${this.minWidth}px` };
    },
  },
  mounted() {
    this.referenceElm = this.$parent.$refs.reference;
    // eslint-disable-next-line
    this.$parent.popperElm = this.popperElm = this.$el;
    this.currentPlacement = 'bottom-start';
    this.currentPopperModifiers = {
      // flip: {
      //   fallbackPlacements: ['top'],
      //   boundary: document.querySelector('#app'),
      // },
      preventOverflow: {
        altAxis: true,
        boundary: document.querySelector('#app'),
      },
    };
  },
};
</script>

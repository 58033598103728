import { generateUrl as generateUrl2 } from './utils';

export default {
  getOrderCommentTrans() {
    return generateUrl2('/api/market/order_comment/get_trans', false);
  },
  getIntegrateInitInfo() {
    return generateUrl2('/api/integrate/init_info', false);
  },
  getI18n() {
    return generateUrl2('/api/i18n/', false);
  },
  getGameList() {
    return generateUrl2('/api/market/c2c/app_list', false);
  },
  // 获取首页其他模块信息
  getHomeModule() {
    return generateUrl2('/api/market/c2c/module_goods_list', false);
  },
  getGameListC2C() {
    return generateUrl2('/api/market/c2c/goods_list', false);
  },
  getGameAppServiceC2C() {
    return generateUrl2('/api/v2/market/game_app_service/', false);
  },
  getGameModuleAppC2C() {
    return generateUrl2('/api/market/c2c/module_app_list', false);
  },
  // 获取主站消息预览
  getLootbarMessages() {
    return generateUrl2('/api/account/notice/preview', false);
  },
  // 确认收货
  getBuyOrderConfirm() {
    return generateUrl2('/api/market/buy_order/confirm', false);
  },
  // 将我的出售订单设置发货中
  getBuyOrderDeliver() {
    return generateUrl2('/api/market/seller/buy_order/deliver', false);
  },
  // 将我的出售订单跳过表单提交
  getBuyOrderSkipSubmit() {
    return generateUrl2('/api/market/seller/buy_order/skip_submit', false);
  },
  // 添加评价
  getLootbarOrderCommentAdd() {
    return generateUrl2('/api/market/order_comment/add', false);
  },
  // 查看评价详情
  getLootbarOrderCommentDetail() {
    return generateUrl2('/api/market/order_comment/get', false);
  },
  // 获取邀评链接
  getInviteLink() {
    return generateUrl2('/api/tp/gen_invite_link', false);
  },
  // 查询评价列表
  getSellOrderCommentQuery() {
    return generateUrl2('/api/market/sell_order_comment/query', false);
  },
  // 校验购买表单信息
  postBuyVerifySubmit() {
    return generateUrl2('/api/market/goods/buy/verify_submit', false);
  },
  // 校验购买表单信息结果查询
  getBuyQueryVerifySubmitResult() {
    return generateUrl2('/api/market/goods/buy/query_verify_submit_result', false);
  },
  // 查询原神信息
  getGenshinImpactProfile() {
    return generateUrl2('/api/system/genshin_profile', false);
  },
  // 查询原神信息
  getGenshinImpactProfileHistory() {
    return generateUrl2('/api/system/genshin_profile_history', false);
  },
  // 导航栏游戏列表
  getNavGameAppList() {
    return generateUrl2('/api/v2/market/nav_game_app/list', false);
  },
};

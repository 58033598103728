import { generateUrl as generateUrl2 } from './utils';

export default {

  getMarketGoods() {
    // return generateUrl2('/api/market/goods/sell_order', false);
    return generateUrl2('/api/market/goods', false);
  },
  // 最全求购
  // getMarketGoodsOrdersAll() {
  //   return generateUrl('/api/market/goods/all', false);
  // },
  // 求购专区
  // getMarketGoodsOrdersBuying() {
  //   return generateUrl('/api/market/goods/buying', false);
  // },

  // csgo sticker
  // getMarketOrderTags() {
  //   return generateUrl('/api/market/order_tags', false);
  // },
  // csgo tournament
  // getTournamentData() {
  //   return generateUrl('/api/market/csgo_tournament_data', false);
  // },

  getMarketSellOrderPreview() {
    return generateUrl2('/api/market/sell_order/preview/manual_plus/v2', false);
  },
  getPartnerSellOrderPreview() {
    return generateUrl2('/api/market/sell_order/preview/partner/v2', false);
  },
  getMarketBatchFee() {
    return generateUrl2('/api/market/batch/fee', false);
  },
  getMarketPricesFromIncomes() {
    return generateUrl2('/api/market/get_prices_from_incomes', false);
  },
  // postMarketCreateSellOrder() {
  //   return generateUrl('/api/market/sell_order/create/manual_plus', false);
  // },
  postPartnerCreateSellOrder() {
    return generateUrl2('/api/market/sell_order/create', false);
  },

  getMarketGoodsInfo() {
    return generateUrl2('/api/market/goods/info', false);
  },
  // getMarketGoodsContainer() {
  //   return generateUrl('/api/market/csgo_goods_containers', false);
  // },
  // getMarketContainer() {
  //   return generateUrl('/api/market/csgo_container', false);
  // },

  getMarketItemDetail() {
    return generateUrl2('/api/market/item_detail', false);
  },

  getMarketGoodsSellOrder() {
    return generateUrl2('/api/market/goods/sell_order', false);
  },
  getMarketBuyOrderExtra() {
    return generateUrl2('/api/market/buy_order/extra', false);
  },
  // 添加关注或收藏
  postMarketAddBookMark() {
    return generateUrl2('/api/account/add_bookmark', false);
  },
  // 移除关注或收藏
  deleteMarketBookMark() {
    return generateUrl2('/api/account/delete_bookmark', false);
  },
  // 置顶收藏或关注
  makeThingToTop() {
    return generateUrl2('/api/account/top_bookmark', false);
  },
  // 清除下架的关注商品
  cleanSoldOut() {
    return generateUrl2('/api/account/bookmark/sell_order/clear_invalid', false);
  },
  // getMarketGoodsBuyOrder() {
  //   return generateUrl('/api/market/goods/buy_order', false);
  // },
  getMarketGoodsBillOrder() {
    return generateUrl2('/api/market/goods/bill_order', false);
  },
  getMarketGoodsPriceHistory() {
    // if (channel === 1) {
    return generateUrl2('/api/market/goods/price_history/', false);
    // }
    // return generateUrl('/api/market/goods/price_history', false);
  },
  getMarketGoodsPriceDuration() {
    // if (Number(channel) === 1) {
    return generateUrl2('/api/market/goods/price_history/days', false);
    // }
    // return generateUrl('/api/market/goods/price_history/steam/days', false);
  },
  // 设置开放提醒
  setRemind() {
    return generateUrl2('/api/market/goods/set_tradable_remind', false);
  },
  // 设置上架提醒
  arrivalRemind() {
    return generateUrl2('/api/market/goods/set_onsell_remind', false);
  },
  getMarketGoodsInspect() {
    return generateUrl2('/api/market/goods/view_info', false);
  },
  getMarketTags() {
    return generateUrl2('/api/market/tags', false);
  },
  // getMarketPaintwearRank() {
  //   return generateUrl('/api/market/paintwear_rank', false);
  // },
  // getMarketGoods3dInspectStatus() {
  //   return generateUrl('/api/market/check_3d_inspect', false);
  // },
  // updateMarketSteamAssetState(type) {
  //   return generateUrl(`/api/market/csgo_asset/${type}`, false);
  // },

  // 购买 还价
  // getMarketBargainPreview() {
  //   return generateUrl('/api/market/goods/buy/preview', false);
  // },
  // postMarketBargain() {
  //   return generateUrl('/api/market/buyer_bargain/create', false);
  // },
  // 获取磨损
  // postAssetCreate() {
  //   return generateUrl('/api/market/steam/asset/create', false);
  // },
  // 刷新印花
  // postRefreshPaintwear() {
  //   return generateUrl('/api/market/csgo_asset/refresh_paintwear', false);
  // },
  // 检查游戏中检视链接是否正确
  // getTryInGameLink(link) {
  //   return generateUrl(link, false);
  // },
};

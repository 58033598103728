import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '@/locale/en.json';

Vue.use(VueI18n);

const loadedLanguages = ['en'];

// 避免异步加载语言时，语言加载完成前，用户已经切换了语言
let seed = 0;

export const i18n = new VueI18n({
  locale: 'en',
  messages: { en },
  fallbackLocale: 'en',
});

function setI18nLanguage(lang) {
  seed += 1;
  i18n.locale = lang;
  document.querySelector('html').setAttribute('lang', lang);
  return lang;
}

export function loadLanguageAsyncWithoutSet(lang) {
  if (loadedLanguages.includes(lang)) return Promise.resolve();
  // eslint-disable-next-line prefer-template
  return import(/* webpackChunkName: "lang-[request]" */ '@/locale/' + lang + '.json').then(
    (messages) => {
      i18n.setLocaleMessage(lang, messages);
      loadedLanguages.push(lang);
    },
  );
}

export function loadLanguageAsync(lang) {
  // 如果语言相同
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // 如果语言已经加载
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  const _seed = seed;

  // 如果尚未加载语言
  // eslint-disable-next-line prefer-template
  return import(/* webpackChunkName: "lang-[request]" */ '@/locale/' + lang + '.json').then(
    (messages) => {
      i18n.setLocaleMessage(lang, messages);
      loadedLanguages.push(lang);
      if (_seed !== seed) return i18n.locale;
      return setI18nLanguage(lang);
    },
  );
}

const host = process.env.VUE_APP_FEEDBACK_APIHOST;

export default {
  postFeedbackAdd() {
    return `${host}/v1/feedback/add/`;
  },
  postFeedbackReply() {
    return `${host}/v1/feedback/append/`;
  },
  getFeedbackList() {
    return `${host}/v1/feedback/`;
  },
  getFeedbackDetail(feedbackId) {
    return `${host}/v1/feedback/${feedbackId}/`;
  },
  postFeedbackClose(feedbackId) {
    return `${host}/v1/feedback/${feedbackId}/close`;
  },
  getFeedbackProductConf() {
    return `${host}/v1/product/conf`;
  },
  getFeedbackProductCustomQuestions() {
    return `${host}/v1/product/custom_questions/`;
  },
  getFeedbackHelp() {
    return `${host}/v1/help/`;
  },
  getFeedbackHelpDetail(helpId) {
    return `${host}/v1/help/${helpId}/`;
  },
  getFeedbackHelpSearch() {
    return `${host}/v1/help/search`;
  },
  getFeedbackFpToken() {
    return `${host}/v1/filepicker/token/`;
  },
  getFeedbackFpFormatUrl() {
    return `${host}/v1/filepicker/format_url/`;
  },
  getFeedbackUnread() {
    return `${host}/v1/feedback/unread/`;
  },
  getFeedbackLangConf() {
    return `${host}/v1/product/lang_conf/`;
  },
};
